import React from 'react'
import styles from './Bio.module.css'
import { getGravatarURL } from '../utils/getGravatarURL'

function Bio(props) {
  let photoURL = getGravatarURL({
    email: "nicolas.richeton@gmail.com",
    size: 56,
  })

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={photoURL} alt="Me" />
      <p>
       Engagement Manager x Architect x SAFe Program Consultant @<a href="https://www.capgemini.com" >Capgemini</a>
      <br />
      Owner @<a href="https://richeton.com" >Nicolas Richeton Consulting  </a>
      <br />
      GitHub repositories: <a href="https://github.com/nricheton" >nricheton</a>
      </p>
    </div>
  )
}

export default Bio
